import axios from 'axios'
import { getRefreshToken, getToken, isLoggedIn, logout, setToken } from './browser'
import { API_TEMPLATE } from './constant'
import { formatUrl } from './url'
// import { toJson } from './util'
import { message } from 'antd'
message.config({
  top: 30,
  maxCount: 1
})

const instance = axios.create({
  baseURL: process.env.API_ENDPOINT || '/'
})
instance.defaults.headers.common['Accept-Language'] = 'vi'
instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error?.response?.status >= 400 && error?.response?.status !== 404) {
    const errorMessage = `Code: ${error.response.status}, ${error.response.data?.message || 'Lỗi xảy ra, vui lòng thử lại'}`
    message.error(errorMessage)
  }
  throw error
})

export function callDelete (path) {
  // console.debug(`libs/query - callDelete - path: ${path}`)

  return instance.delete(path, getConfig()).then(res => {
    return handleResponse(res)
  }).catch(error => {
    return handleError(error)
  })
}

export function get (path) {
  // console.debug(`libs/query - get - path: ${path}`)
  const { cancel, cancelToken } = handleCancelRequest()
  const promise = instance.get(path, { ...getConfig(), cancelToken }).then(res => {
    return handleResponse(res)
  }).catch(error => {
    return handleError(error)
  })
  promise.cancel = cancel
  return promise
}

/**
 * 
 * @param {string} path 
 * @param {any} data 
 * @param {AxiosRequestConfig} config 
 * @returns 
 */

export function post (path, data, config) {
  // console.debug(`libs/query - post - path: ${path}, data: ${toJson(data)}`)
  const { cancel, cancelToken } = handleCancelRequest()
  const promise = instance.post(path, data, { ...config, ...getConfig(), cancelToken }).then(res => {
    return handleResponse(res)
  }).catch(error => {
    return handleError(error)
  })
  promise.cancel = cancel
  return promise
}
export function postWithoutToken (path, data) {
  // console.debug(`libs/query - post - path: ${path}, data: ${toJson(data)}`)

  return instance.post(path, data).then(res => {
    return handleResponse(res)
  }).catch(error => {
    return handleError(error)
  })
}

export function patch (path, data) {
  // console.debug(`libs/query - patch - path: ${path}, data: ${toJson(data)}`)

  return instance.patch(path, data, getConfig()).then(res => {
    return handleResponse(res)
  }).catch(error => {
    return handleError(error)
  })
}
/**
 * 
 * @param {string} path 
 * @param {any} data 
 * @param {AxiosRequestConfig} config 
 * @returns 
 */
export function put (path, data, config) {
  // console.debug(`libs/query - put - path: ${path}, data: ${toJson(data)}`)

  return instance.put(path, data, { ...config, ...getConfig()}).then(res => {
    return handleResponse(res)
  }).catch(error => {
    return handleError(error)
  })
}

export function upload (path, form) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: getToken()
    }
  }
  return instance.post(path, form, config).then(res => {
    return handleResponse(res)
  }).catch(error => {
    return handleError(error)
  })
}

let refreshRequest
function handleError (error) {
  // console.debug(`libs/query - handleError - error: ${toJson(error)}`)
  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    // console.debug(`libs/query - handleError - error data: ${toJson(error.response.data)}`)
    // console.debug(`libs/query - handleError - error status: ${toJson(error.response.status)}`)
    // console.debug(`libs/query - handleError - error headers: ${toJson(error.response.headers)}`)

    if (error.response.status === 401 && isLoggedIn()) {
      const refreshToken = getRefreshToken()
      if (refreshToken) {
        const refreshTokenUrl = formatUrl(API_TEMPLATE.AUTH_REFRESH_TOKEN, [
          { key: 'refreshToken', value: getRefreshToken() }
        ])
        refreshRequest =
            refreshRequest ||
            instance.post(refreshTokenUrl)
              .then(({ data }) => {
                setToken(data)
              })
              .catch(() => {
                logoutAndgoToLogin()
              })
      } else {
        logoutAndgoToLogin()
      }
    }
    if (error.response.status >= 400) {
      // console.debug(`libs/query - handleError - error message: ${error.response.data.message}`)
      return { status: error.response.status, code: error.response.data.code, data: error.response.data.message }
    }
    return { status: error.response.status }
  } else if (error.request) {
    // The request was made but no response was received
    // console.debug(`libs/query - handleError - error request: ${toJson(error.request)}`)
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.debug(`libs/query - handleError - error setting up the request: ${toJson(error.message)}`)
  }

  return { code: 999 }
}

function handleResponse (response) {
  let code = ''
  let data
  if (response.status === 200) {
    data = response.data
  }

  if (response.status === 400) {
    code = response.data.code
    data = response.data.message || 'Bad request'
  }

  if (response.status === 403) {
    data = 'No permission'
  }

  if (response.status >= 500) {
    data = 'Internal Server Error'
  }

  // console.debug(`libs/query - handleResponse - code: ${code}, status: ${response.status}`)
  return {
    code,
    data,
    status: response.status
  }
}

function getConfig () {
  return {
    headers: {
      Authorization: getToken()
    }
  }
}

function handleCancelRequest () {
  const source = axios.CancelToken.source()
  const cancelToken = source.token
  return {
    cancelToken,
    cancel: () => {
      source.cancel('cancel request')
    }
  }
}
const logoutAndgoToLogin = () => {
  const currentUrl = window.location.pathname + window.location.search
  logout()
  window.location.replace(`/login?from=${encodeURIComponent(currentUrl)}`)
}
